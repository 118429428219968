// 
// tabs.scss
//

.nav-tabs {
    padding-left: 0;
    > li {
        > a:hover {
            cursor: pointer;

        }
    }
}

.nav-tabs,.nav-pills {
    list-style: none;
    > li {
        > a {
            color: $gray-700;
            font-weight: $font-weight-semibold;
        }
    }
}

.nav-pills {
    > a {
        color: $gray-700;
        font-weight: $font-weight-semibold;   
    }
}


.bg-nav-pills {
    background-color: $nav-pills-bg;
}

// 
// nav-bordered
//

.nav-tabs.nav-bordered {
    border-bottom: 2px solid rgba($gray-600, 0.2);

    .nav-item {
        margin-bottom: -1px;
    }

    li {
        padding: 0.625rem 1.25rem;
        min-width: 100px;
        &.active {
            border-bottom: 2px solid $primary;
        }
    }
}