// 
// pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
  // float: right !important;
  .page-link {
    border-radius: 30px !important;
    margin: 0 3px !important;
    border: none !important;
    padding: 0.375rem 0.75rem !important;
  }

  button[disabled] {
    color: $gray-500 !important;
  }
}