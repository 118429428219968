//
// react-table.scss
//

.react-table {

    th,
    td {
        white-space: nowrap;
    }

    th {
        //padding-left: $table-cell-padding-y;
        padding-right: 30px;
    }

    .sortable {
        position: relative;
        cursor: pointer;

        &:before {
            position: absolute;
            right: .5rem;
            left: auto;
            content: "\F0360";
            font-family: "Material Design Icons";
            font-size: 1rem;
            // top: 12px;
            top: -4px;
            opacity: 0.5;
        }

        &:after {
            position: absolute;
            right: .5em;
            left: auto;
            content: "\F035D";
            font-family: "Material Design Icons";
            // top: 18px;
            top: 2px;
            opacity: 0.5;
            font-size: 1rem;
        }

        &.sorting_asc {
            &:before {
                opacity: 1;
            }
        }

        &.sorting_desc {
            &:after {
                opacity: 1;
            }
        }
    }
}

//CUSTOM
.resizer {
    display: inline-block;
    // background: #b3b6c3;
    width: 1px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    touch-action: none;

    &.isResizing {
        background: red;
    }
}

.react-table td {
    overflow: hidden;
}
