.space-lang {
    margin-right: 8px;
}

.space-vehicle {
    margin-right: 26px;
}

.space-phone {
    margin-right: 13px;
}

.space-checkbox {
    margin-right: 5px;
}

.type-icon {
    font-size: 13px;
    color: grey;
}

.background-icon {
    background-color: #F1FFF0;
    width: 60px;
    border: 1px solid #F0F0F0;
}

.duty-off {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    position: absolute;
    background-color: #707070;
    margin-left: 32px;
    margin-top: 36px;
    border: 1px solid white;
}

.duty-on {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    position: absolute;
    background-color: #00a67e;
    margin-left: 32px;
    margin-top: 36px;
    border: 1px solid white;
}

.left-col {
    display: flex;
}

.phone-icons {
    margin-right: 22px;
}

@media screen and (max-width: 556px) {
    .left-icons {
        display: flex;
        flex-direction: column;
        width: 75px;
    }

    .left-icons span {
        margin-bottom: 5px;
    }

    .left-col {
        display: block;
    }

    .phone-icons {
        margin: 0 0;
    }
}