// 
// tables.scss
//

//Table centered (Custom)
.table-centered {
    th, td {
        vertical-align: middle !important;
    }
}


// Custom table components (Custom)
.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
}

.action-icon {
    color: $gray-600;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;
    border: 0;
    background-color: transparent;

    &:hover {
        color: $gray-700;
    }
}

// Tables fluid
.table-nowrap {
    th, td {
      white-space: nowrap;
    }
}

//CUSTOM
.table> :not(caption)>*>* {
    padding: 0 5px 0 0;
  }
  
  .table-responsive {
    max-height: calc(100vh - 230px);
    min-height: 500px;
    overflow-y: hidden;
  }
  
  tr {
    white-space: nowrap;
  }
  
  .table-hover>tbody>tr:hover {
    --bs-table-accent-bg: #abb8e5 !important;
  }
  
  th {
    overflow: hidden;
  }

  .custom-table {
    position: relative;
    height: calc(100vh - 390px);
    overflow: auto;
    will-change: transform;
    direction: ltr;
  }
